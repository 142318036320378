import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import ContentContainer from "../Core/Containers/ContentContainer"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { ButtonStyleDark } from "../Core/Buttons/Styles"

const IntroBlockContainer = styled.div`
  text-align: center;
  margin-bottom: 60px;
  @media (min-width: ${breakpoints.sm}) {
    padding: 0 30px;
  }
`

const Intro = styled.div`
  max-width: 720px;
  margin: auto;
`

const Title = styled.h1`
  ${fonts.garamondRegular};
  font-size: 40px;
  line-height: 1.1;
  letter-spacing: -0.3px;
  margin-bottom: 30px;

  @media (min-width: ${breakpoints.md}) {
    font-size: 46px;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 60px;
  }
`
const IntroContent = styled.div`
  ${fonts.sofiaPro};
  color: ${colors.charcoal};
  font-size: 16px;
  line-height: 1.33;

  p {
    margin-bottom: 28px;
    :last-of-type {
      margin-bottom: 0;
    }
  }

  b {
    ${fonts.sofiaProBold};
  }

  a {
    color: inherit;
  }

  i {
    font-style: italic;
  }
`

const CtaContainer = styled.div`
  margin-top: 40px;
`

const StyledExternalLink = styled.a`
  ${ButtonStyleDark}
`

const IntroBlock = (props) => {
  const { title, content, ctaLink, ctaLabel } = props

  return (
    <IntroBlockContainer>
      <ContentContainer>
        <Intro>
        <Title>{title}</Title>
        <IntroContent>{renderRichText(content)}</IntroContent>
        {ctaLink && ctaLabel && (
          <CtaContainer>
            <StyledExternalLink href={ctaLink} target={"_blank"}>
              {ctaLabel}
            </StyledExternalLink>
          </CtaContainer>
        )}
        </Intro>
      </ContentContainer>
    </IntroBlockContainer>
  )
}

export default IntroBlock
