import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const MediaBlockContainer = styled.div`
  padding-bottom:70px;
  @media (min-width: ${breakpoints.md}) {
    padding: 0 60px 70px;
  }
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StyledImage = styled(GatsbyImage)`
  max-width: 720px;
`

const MediaBlock = (props) => {
  const { media } = props
  return (
    <MediaBlockContainer>
      <ImageContainer>
        <StyledImage image={getImage(media)} alt={media.title || ""} />
      </ImageContainer>
    </MediaBlockContainer>
  )
}

export default MediaBlock
